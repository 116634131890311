<template>
  <TTView>
    <VRow>
      <VCol
        cols="12"
        sm="10"
        md="8"
        lg="7"
      >
        <TeamForm
          :entity="team"
          :loading="loading"
          :teams="teams"
          @update:name="team.name = $event"
          @update:displayName="team.displayName = $event"
          @update:teamType="team.teamType = $event"
          @update:externalId="team.externalId = $event"
          @update:parentId="team.parentId = $event"
          @submit="handleCreateTeam"
          @cancel="handleCancel"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import _ from 'lodash';
import TeamForm from '../../../components/v2/TeamForm.vue';

const createTeamDTO = {
  id: null,
  name: null,
  displayName: null,
  teamType: null,
  companyId: null,
  externalId: null,
  parentId: null,
};

export default {
  name: 'TeamCreateView',

  components: { TeamForm },

  inject: ['Names'],

  props: {
    accountId: {
      type: String,
      required: true,
    },

    companyId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      team: _.cloneDeep(createTeamDTO),
      teams: [],
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { teams } = await this.$di.api.Orgstructure.teamIndex(this.companyId);

        this.teams = teams;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleCreateTeam() {
      try {
        this.loading = true;
        const dto = _.cloneDeep({ ...this.team, companyId: this.companyId });
        const app = new this.$di.ddd.Orgstructure();

        await app.services.team.createTeam(dto);

        this.$di.notify.snackSuccess('Команда успешно создана');

        this.$router.push({ name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW_TEAM_LIST });
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
